import React from 'react'
import Layout from '../../components/layout'
import Header from '../../components/Header'
import { Helmet } from 'react-helmet'
import Sidebar from '../../components/Sidebar/Sidebar'

const Cukrus = () => (
  <Layout>
      <Helmet>
          <title>Reagentai Cukraus Pramonei</title>
      </Helmet>
      <Header
        styleName='header header-sugar'
        title='Cukraus pramonė'
        />
      <div className="columns">
        <div className="column content">
          <div className="title-small-nomargintop title-small content-title">Reagentai cukraus pramonei</div>
          <div className="content-text">UAB „Inžinerinė ekologija“ užsiima aukšto technologinio lygio sprendimų įgyvendinimu ir kokybiškų cheminių medžiagų bei modernios įrangos tiekimu technologiniam procesui cukraus pramonėje:</div>
          <ul>
              <li className="content-text">Nuovirų inhibitoriai, skirti ekonomiškam cukraus sirupo garintuvų darbui bei kitiems aukštoje temperatūroje vykstantiems procesams</li>
              <li className="content-text">Difuzijos įrenginio dezinfekcija ir viso gamybos ciklo apsauga nuo biologinio apaugimo. Dekstrano susidarymo ir cukringumo sumažėjimo prevencija, Leuconostoc bakterijų kontrolė</li>
              <li className="content-text">Organiniai antiputintojai transportavimo-plovimo vandeniui ir difuzinėms sultims</li>
              <li className="content-text">Sintetiniai koaguliantai ir flokuliantai pirminių sulčių sėsdinimui ir skaidrinimui</li>
              <li className="content-text">Plovimo priemonių koncentratai, skirti įrangos, veikiančios aukštoje temperatūroje, plovimui</li>
              <li className="content-text">Dozavimo įranga: dozavimo siurbliai, pompos, automatinio dozavimo įrenginiai</li>
          </ul>
        </div>
        <Sidebar
        analysis='Anketos'
        boiler='Boileriams'
        cooling='Aušinimui'
        ro='Osmosui'
        broshure='IN-ECO brošiura'
        certificates='Sertifikatai'
        />
      </div>
    </Layout>
)

export default Cukrus